
// Fonts
// @import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700');
@import url(http://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import "fonts";

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "reset";

// Layouts
@import "layout/typography";
@import "layout/layout";

// Partials
// @import "partials/table";
@import "partials/articles";
@import "partials/filters";
@import "partials/call-to-action";

// Components
@import "components/buttons";
@import "components/navbar";
@import "components/icons";
@import "components/footer";
@import "components/forms";
@import "components/banners";
@import "components/cards";
@import "components/world";
@import "components/lists";
@import "components/slideshow";
@import "components/timeline";
@import "components/gallery";
// @import "components/accordion";
