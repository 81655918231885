body{
  // background-image:url('/front/img/photos/bg.png');
  color: $black-text-color;

    *{
      letter-spacing: .04em;
    }
}

/**
 * Container
 */
.container{
    position: relative;

    &--shrink{
      margin: auto;

      @media (min-width: $screen-sm-min) {
        padding: 0 10%;
      }
    }
}

/**
 * Section
 */
 .navbar + .section {
   padding-top: 90px;

   @media (min-width: $screen-sm-min) {
   padding-top: 120px;
   }
}
.section{
  position: relative;
  padding:40px 0 40px;

  @media (min-width: $screen-sm-min) {
    padding:40px 0 60px;
    // padding:60px 0 60px;
  }

  &--pattern{
    @extend .full-img;
    background-repeat: repeat!important;
    background-size: auto !important;
    background-image: url(/front/img/photos/bg/bg-light.jpg);
  }

  &--bg{
    @extend .full-img;
  }
  &--top{
    padding: 0;
  }
  &--padding{
    padding:40px 0 0;
  }
  &--padding-up{
    padding: 0 0 40px;

    @media (min-width: $screen-sm-min) {
      padding: 0 0 60px;
    }
  }
  &.black{
    background-color: $black-bg-color;
  }

  &__header{
    text-align: center;
    position: relative;
    margin-top: 0;
    margin-bottom: 40px;

    .divider{
      margin-top: 20px;
      width: 300px;
    }
    > .subtitle{
      margin-top: 25px;
    }
    &.brown{
      .title,
      .divider{
        color: $brown-text-color;
        fill: $brown-text-color;
      }
    }
    &.light{
      .subtitle,
      .title,
      .divider{
        color: $light-brown-text-color;
        fill: $light-brown-text-color;
      }
    }
    &.small{

      @media (min-width: $screen-sm-min) {
        padding: 0 5%;
      }
    }
  }

  &__container{
    margin-top: 25px;

    @media (min-width: $screen-sm-min) {
      margin-top: 60px;
    }
  }
}


// BG-COLOR
.white-bg-color{
  background-color: $white-bg-color;
}
.dirty-bg-color{
  background-color: $dirty-bg-color;
}

/**
 * Share
 */
.share{
  >li{
    cursor: pointer;
    display: inline-block;
    width: 50px;
    line-height: 50px;
    font-size: 22px;
    border: 1px solid $white-border-color;
    text-align: center;
    transition: .3s all;
    a{
      display: block;
    }

    &:hover{
      background-color: $white-bg-color;
    }
  }
  @media (min-width: $screen-sm-min) {
    float: left;
  }
  &__social{
    position: relative;
    >ul{
      z-index: 3;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: $light-brown-bg-color;
      color: $white-text-color;

      li{
        &:hover{
          a{
            color: $black-text-color;
          }
        }
      }
    }
  }
}

/**
 * Page nav
 */
.page-nav{
  float: right;

  &__links{
    font-size: 16px;
    color: $brown-text-color;

    @media (min-width: $screen-sm-min) {
      float: right;
    }
    .icon{
      width: 50px;
      line-height: 50px;
      text-align: center;
      border: 1px solid $white-border-color;
      font-size: 22px;
    }
    a:hover{
      background-color: white;
    }

    *{
      display: inline-block !important;
    }

  }
}

.bg-divider{
  position: relative;
  bottom:0;
  z-index: 2;
  height: auto;
  width: 100%;
  @extend .full-img;

  &--up{
    margin-top: 60px;
    bottom:-2px;
  }
  &--down{
    transform: rotate(180deg);
    margin-bottom: 40px;
  }
}

// CENTER CONTENT
.abs-center{
  margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
  right: 0;
	left: 0;
}

// FULL WITH&HEIGHT BG IMAGE
.full-img{
  background-repeat: no-repeat;
  background-position: center;
  background-position: center;
  background-size: cover;
}
