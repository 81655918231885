.gallery{
  overflow:hidden;

  &:before {
    content: '';
    display: block;
    clear: both;
  }

  &--video{
    &:hover{
      .gallery__img::after{
        opacity:.2;
      }
    }
    .gallery__img::after{
      opacity:.5;
    }
    .gallery__img::before{
      opacity:1;
      content: '\f488';
    }
  }

  &__element{
    width: calc(50% - 30px);
    float: left;
    margin: 0 15px;
    position: relative;
    cursor:pointer;


    &:nth-of-type(n+3){
      margin-top: 30px;
    }
    //
    // //hover effect

    &::before{
      z-index: 2;
      opacity: 0;
      font-size: 30px;
      color: white;
      font-family: 'Ionicons';
      content:'\f4a4';
      position: absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      margin: auto;
      width: 50px;
      height: 50px;
      text-align: center;
      border: 2px solid white;
      transition: all .3s;
    }
    &::after{
      opacity: 0;
      content:'';
      position: absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      background-color: $brown-bg-color;
      transition: all .3s;
    }
    
    &:hover{
      &::before{
        opacity: 1;
      }
      &::after{
        opacity: .5;
      }
    }
  }
  &__img{
    width:100%;
    height:100%;

  }

}
