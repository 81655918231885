
/**
 * Colors
 */
$colors: (
    'black'             : #100c06,
    'dark-brown'        : #20190f,
    'brown'             : #564330,
    'light-brown'       : #706151,
    'dirty'             : #dad6cc,
    'white'             : #fff,
);

/**
 * Color applications
 */

 /* text */
 $black-text-color                : map-get($colors, 'black');
 $dark-brown-text-color           : map-get($colors, 'dark-brown');
 $brown-text-color                : map-get($colors, 'brown');
 $light-brown-text-color          : map-get($colors, 'light-brown');
 $dirty-text-color                : map-get($colors, 'dirty');
 $white-text-color                : map-get($colors, 'white');

/* backgrounds */
$black-bg-color                   : map-get($colors, 'black');
$dark-brown-bg-color               : map-get($colors, 'dark-brown');
$brown-bg-color                  : map-get($colors, 'brown');
$light-brown-bg-color              : map-get($colors, 'light-brown');
$dirty-bg-color                   : map-get($colors, 'dirty');
$white-bg-color                   : map-get($colors, 'white');

/* borders */
$black-border-color               : map-get($colors, 'black');
$dark-brown-border-color               : map-get($colors, 'dark-brown');
$brown-border-color                  : map-get($colors, 'brown');
$light-brown-border-color              : map-get($colors, 'light-brown');
$dirty-border-color               : map-get($colors, 'dirty');
$white-border-color               : map-get($colors, 'white');


// Typography
$font-family-icons: "Ionicons";
$font-family-primary: 'Roboto Slab', serif;
$font-size-base: 14px;
$line-height-base: 1.6;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

// Body
$body-bg: #fff;

// Navbar
$navbar-default-bg: #fff;


// Panels
$panel-default-heading-bg: #fff;
