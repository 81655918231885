.btn{
  padding: 0 25px;
  position: relative;
  min-width: 100px;
  max-width: 260px;
  height: 50px;
  line-height: 48px;
  font-size: 18px;
  text-align: center;
  transition: all .3s;
  outline: none;
  font-style: normal;
  font-weight: $font-weight-bold;
  border-radius: 0;

  &:focus{
    outline: none;
  }

  &--center{
    display: block;
    margin: 35px auto 0;
  }
  &--white{
    border: 2px solid white;
    color: $brown-text-color;
    &:hover{
      border: 2px solid $brown-text-color;
    }
  }
  &--brown{
    border: 2px solid $brown-border-color;
    color: $brown-text-color;
    &:hover{
      border: 2px solid $white-border-color;
    }
  }
  .icon{
    margin-left:10px;
  }
}

.link{
  color: $brown-text-color;
  font-weight: $font-weight-bold;

  &:hover{
    color: $black-text-color;
    text-decoration: underline;
  }
}
.btn-link{
  // max-width: 180px;
  cursor: pointer;
  display: block;
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;
  font-weight: $font-weight-bold;

  &--white{
    color: $dirty-text-color;

    &:hover{
      color: $black-text-color;
    }
  }
  &--brown{
    color: $brown-text-color;
    &:hover{
      color: $dirty-text-color;
    }
  }
}
