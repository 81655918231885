.page-footer{
  background-color: $brown-bg-color;
  padding-top: 20px;

  @media (min-width: $screen-sm-min) {
    padding-top: 0;
  }

  &__imgs{
    text-align: center;

    *{
      display: inline-block;
      vertical-align: top;
    }

    .app-logo{
      width: 260px;
      height: 70px;
      margin-top: 20px;

      @media (min-width: $screen-sm-min) {
        margin-top: 0;
        width: 480px;
        height: 180px;
      }
    }
  }
  &__60{
    width: 130px;
    height: 130px;
  }
  &__text{
    margin: 10px 0 25px;
    text-align: center;
    h4{
      text-transform: uppercase;
      font-weight: $font-weight-bold;
    }
  }
  &__newsletter{
    margin: 15px auto 0;
  }
  &__bottom{
    margin-top: 30px;
    text-align: center;

    @media (min-width: $screen-sm-min) {
      margin-top: 60px;
      text-align: left;
    }

  }
  &__up{
    cursor: pointer;
    a{
      text-align: center;
      width: 50px;
      height: 60px;
      line-height: 60px;
      font-size: 60px;
      display: block;
      margin: auto;

      &:hover{
        transform: translateY(-20px);
      }
    }
  }

  &__links{
    text-align: center;

    @media (min-width: $screen-sm-min) {
      text-align: right;
    }

    li{
      position: relative;
      padding-right: 10px;

      &:not(:last-of-type){
         &::after{
            content: '/';
            position: absolute;
            right: 0;
            top: 0;
            opacity: 0;
         }
      }
      @media (min-width: $screen-sm-min) {
         &:not(:last-of-type){
            &::after{
               opacity: 1;
            }
         }
      }

      &:nth-of-type(n+2){
        margin-top: 10px;
      }
      @media (min-width: $screen-sm-min) {
        display: inline-block;

        &:nth-of-type(n+2){
          margin-top: 0;
        }
      }
      a{
        font-size: 18px;
        font-weight: $font-weight-bold;
        color: $black-text-color;
        text-decoration: underline;
      }
    }
  }
  &__footer{
    margin-top: 15px;
    padding-bottom: 25px;
  }
  &__col{
    font-weight: $font-weight-bold;
    position: relative;
    font-size: initial;

    @media (min-width: $screen-sm-min) {
      font-size: 14px;
      &::after{
        content: '';
        height: 5px;
        border-top: 1px solid $dark-brown-border-color;
        border-bottom: 1px solid $dark-brown-border-color;
        background-color: transparent;
        width: 100%;
        position: relative;
        display: block;
        margin-top: 20px;
        padding: 3px 0;
      }
    }
  }
  &__credits{
    text-align: center;
    line-height: inherit;
    color: $dark-brown-text-color;

    @media (min-width: $screen-sm-min) {
      font-size: 14px;
      // text-align: left;
    }
    @media (min-width: $screen-md-min) {
      line-height: 70px;
    }

    span{
      text-transform: uppercase;
    }
  }
  &__logos{
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
      margin-top: 0;
      text-align: right;
      // padding-left: 5%;
    }
    &.left{
      text-align: center;

      @media (min-width: $screen-sm-min) {
        text-align: left;
      }
   }
    &.right{
      text-align: center;

      @media (min-width: $screen-sm-min) {
         text-align: right;
      }

      img{
         margin-top: 10px;
         max-height: 70px;
         width: 40px;

         &:not(:nth-child(1n)){
            filter: grayscale(100%);
         }
         @media (min-width: $screen-sm-min) {
            margin-top: 0;
         }
         @media (min-width: $screen-md-min) {
            max-height: 60px;
            width: 50px;
         }
      }
   }

    img{
      max-height: 40px;
      max-width: 50px;
      display: inline-block;
      // margin-left: 2px;

     @media (min-width: $screen-md-min) {
        margin-left: 10px;
      }
     @media (min-width: $screen-lg-min) {
        margin-left: 20px;
      }

      @media (min-width: $screen-sm-min) {
         // float: right;
      }
      @media (min-width: $screen-md-min) {
        max-width: 60px;
        max-height: 60px;
      }

      &:nth-of-type(2n){
         @media (min-width: $screen-md-min) {
            max-width: 60px;
         }
      }
      &:nth-of-type(3n){
         @media (min-width: $screen-md-min) {
            // width: 90px;
            max-width: 90px;
         }
      }
      &:nth-of-type(4n){
         @media (min-width: $screen-md-min) {
            // width: 90px;
            max-width: 90px;
         }
      }
    }
  }
}
