.map-section{
  display: none;

  @media (min-width: 769px) {
    display: block;
  }
}

.world{
  position: relative;
  .small-text{
    transition: .3s all;
  }
  &__map{
    height: 700px;
    @extend .full-img;
    background-size: contain;
  }

  &__pin{
    width: 20px;

    @media (min-width: $screen-md-min) {
      width: 30px;
    }
  }

  &__selector{

  }
  &__info{
    position: absolute;
    cursor: pointer;

    &:hover{
      .world__countries{
        opacity: 1;
        display: block;
      }
    }
    &:hover{
      .small-text{
        color: $black-text-color;
      }

    }
  }
  &__continent,
  &__selector,
  &__countries{
    vertical-align: middle;
    display: inline-block;
  }
  &__countries{
    // opacity: 0;
    display: none;
    position: relative;
    margin-right: 15px;
    background-color: rgba(16, 12, 6, 0.7);
    padding: 15px;
    transition: .3s all;
    z-index: 2;
    position: absolute;
    top:0;
    left:0;
    li{
      color: $dirty-text-color;
      display: block;
    }
  }


  &__caption{
    // color: $dirty-text-color;
    color:black;
    font-weight: 700;
    position: relative;

    svg{
      display: inline-block;
      transform: rotate(30deg);
      line-height: 50px;
      width: 30px;
      transition: .3s all;
    }

  }
  #northamerica{
    top: 260px;
    left: 170px;

    @media (max-width: $screen-md-max) {
      left: 120px;
      top: 250px;
    }

    @media (max-width: $screen-sm-max) {
      left: 80px;
      top: 230px;
    }
  }
  #southamerica{
    top: 440px;
    left: 320px;

    @media (max-width: $screen-md-max) {
      // left: 110px;
      left: 310px;
      top: 400px;
    }
    @media (max-width: $screen-sm-max) {
      top: 390px;
      left: 200px;
    }
  }
  #portugal{
    top: 205px;
    left: 390px;

    @media (max-width: $screen-sm-max) {
      top: 280px;
      left: 320px;
    }
    @media (min-width: $screen-md-min) {
      top: 240px;
      left: 400px;
   }

    @media (min-width: $screen-lg-min) {
      top: 225px;
      left: 510px;
   }

  }
  #africa{
    top: 310px;
    left: 530px;


    @media (max-width: $screen-md-max) {
      left: 430px;
    }
    @media (max-width: $screen-sm-max) {
      top: 330px;
      left: 330px;
    }
  }
  #europa{
    top: 140px;
    left: 490px;

    @media (max-width: $screen-sm-max) {
      top: 250px;
      left: 360px;
    }
   @media (min-width: $screen-md-min) {
      top: 200px;
      left: 490px;
      // left: 350px;
   }
    @media (min-width: $screen-lg-min) {
      top: 170px;
      // left: 400px;
       right: 390px;
       left: initial;
   }
  }
  #asia{
    top: 240px;
    right: 220px;

    @media (max-width: $screen-md-max) {
      right:230px;
    }
    @media (max-width: $screen-sm-max) {
      right: 160px;
      top: 250px;
    }
  }
  #oceania{
    bottom: 160px;
    right: 70px;

    @media (max-width: $screen-md-max) {
      bottom:180px;
    }
    @media (max-width: $screen-sm-max) {
      right: 40px;
      bottom: 230px;
    }
  }
}
