.navbar{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index:3;
  background-color: transparent;
  border: none;
   padding: 0 15px;

   &--homepage{
      .navbar__user,
      .navbar__social-icon,
      .navbar__toggler{
         color: #dad6cc;
      }
   }
  &--homepage + .navbar__collapse + .section {
    padding-top: initial;
  }

  &__collapse + .section {
    padding-top: 110px;

    @media (min-width: $screen-sm-min) {
      padding-top: 150px;
    }
  }

  &__logo{
     margin: 20px auto 0;
     display: block;
 }
 &__right{
   display: none;
   @media (min-width: $screen-sm-min) {
      display: block;
      right: 15px;
   }
 }
  &__left{

     @media (min-width: $screen-sm-min) {
        left: 15px;
    }
 }
  &__left,
  &__right{
     height: 70px;
     line-height: 70px;

     @media (min-width: $screen-sm-min) {
        line-height: 100px;
        height: 100px;
        position: fixed;
        top: 0;
        z-index:3;
     }
  }
  &__social-icon{
     width: 44px;
     font-size: 22px;
     text-align: center;
     display: block;

     &:focus{
        color: #20190f;
     }
  }

  &__langs{
     margin-right: 15px;
  }
  &__social,
  &__langs{
     float: right;
  }

  &__social{
     width: 44px;
     height: auto;
     display: inline-block;

     &:hover{
        .navbar__social-drop{
           opacity: 1;
           transform: matrix(1, 0, 0, 1, 0, 0);

           li{
             opacity: 1;
             transform: matrix(1, 0, 0, 1, 0, 5);
          }
       }
    }
  }
  &__social-drop{
    background-color: #dad6cc;
    width: 50px;
    opacity: 0;
    padding:5px 0;
    transform: matrix(1, 0, 0, 1, 0, -15);
    transition: all 0.4s ease-in-out;


   li{
      display: block !important;
      opacity: 0;
      transform: matrix(1, 0, 0, 1, 0, -15);
      transition: all 0.4s ease-in-out;

      &:hover{
         a{
            color: initial !important;
            opacity: .5;
         }
      }
      &:nth-of-type(n+2){
         margin-left: 0 !important;
      }
   }
 }
  &__collapse{
    height: 100%;
    width: 100%;
    z-index: 3;
   position: fixed;
   top: 0;
   // bottom: 0;
   left: 0;
   right: 0;
   overflow: scroll;

   @media (min-width: $screen-sm-min) {
      overflow: auto;
   }
    &.close{
      display: none;
    }
    > .container {

      @media (min-width: $screen-sm-min) {
        height: calc(100vh - 130px);
      }
    }
    .navbar__logo{
     // float: right !important;
    }
  }

  &__header{
    padding-top: 20px;
    padding: 20px 15px 0;

    @media (min-width: $screen-sm-min) {
      padding: 0 15px 0;
    }
  }
  &__close{
    // float: right;
    font-size: 20px;
    display: block;
    text-align: center;
    color: $dark-brown-text-color;
    line-height: 70px;
    height: 70px;
    cursor: pointer;
    width: 50px;

    @media (min-width: $screen-sm-min) {
      width: 44px;
      height: 100px;
       line-height: 100px;
       font-size: 22px;
    }

    .icon{
      color: $dark-brown-text-color;
    }
    span.menu{
      text-decoration: underline;
    }
    &:hover{
      .icon,
      a{
        color: $dirty-text-color;
      }
   }
  }
  &__wrapper {
     @media (min-width: $screen-sm-min) {
          padding-top: 5vh;
     }
     @media (min-width: $screen-md-min) {
        padding-top: 60px;
     }
     @media (min-width: $screen-lg-min) {
        padding-top: 20vh;
     }
      // display: table;
      // width: 100%;
      // height: calc(100vh - 100px);
      // position: fixed;
      // top: 100px;
      // bottom: 0;
      // left: 0;
      // right: 0;
  }
  &__menu{
    width: 100%;
    padding: 25px 0;
    float: none;
    margin: 0;

    @media (min-width: $screen-sm-min) {
      // display: table-cell;
      // vertical-align: middle;
      // padding: 40px 0;
       margin: auto;
       width: 100%;
    }
    @media (min-width: $screen-md-min) {
      // padding: 40px 2%;
    }

    > li{
      position: relative;
      background-color: transparent;
      text-align: center;
      margin: auto;
      box-sizing: border-box;
      display: block;
      float: none;

      @media (min-width: $screen-sm-min) {
        // display: inline-flex;
      }
      &:nth-of-type(n+2){
         margin-top:15px;
      }
      &:hover{
        a{
          color: $dark-brown-text-color;
        }
       }

      &.active{
        a,
        a:focus{
          color: $dark-brown-text-color !important;
          background-color: transparent !important;
          text-decoration: none;
        }
      }
      > a{
        padding-top: 0;
        padding-bottom: 0;
        line-height: 45px;
        font-size: 20px;
        font-weight: $font-weight-bold;
        color: $brown-text-color !important;
        // color: $dirty-text-color !important;
        text-decoration: none;
        display: block;

        @media (min-width: $screen-sm-min) {
          font-size: 24px;
          line-height: 34px;
        }
      }
    }
  }

  &__dropdown{
    &.open{
      .dropdown-toggle{
         background-color: transparent !important;
        color: $dark-brown-text-color !important;
      }
    }
  }
  &__bottom{
    margin-top: 25px;

    @media (min-width: $screen-sm-min) {
      margin-top: 0;
      position: absolute;
      bottom: 10px;
      left: 15px;
      right: 15px;
    }

    .social{
      display: block;

      @media (min-width: $screen-sm-min) {
         display: none;
      }
   }
  }

  &__user,
  &__toggler{
    display: inline-block;
    float: right;
    color: $black-text-color;
    font-size: 24px;
    padding-top: 20px;
    height: 70px;
    line-height: 70px;
    width: 44px;
    text-align: center;

    @media (min-width: $screen-sm-min) {
      height: 100px;
      line-height: 100px;
      font-size: 22px;
      padding-top: 0;
    }
    &:focus,
    &:hover{
      background-color: transparent !important;
      color: $light-brown-text-color;
    }
  }
  &__user{
    text-align: center;
    position: relative;
  }
  &__toggler{
    display: block;
    cursor: pointer;

    span.menu{
      text-decoration: underline;
    }
    .icon{
      // margin-left: 10px;
    }
    .icon::before{
      font-size: 22px;
    }

  }
}

.social{
  text-align: center;
  margin-top: 15px;

  @media (min-width: $screen-sm-min) {
    margin-top: 0;
    text-align: right;
  }
  li{
     vertical-align: top;
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;

    &:nth-of-type(n+2){
      margin-left: 15px;
    }
    &:hover{
      a{
        color: $dirty-text-color;
      }
    }
    a{
      display: block;
      font-size: 24px;
      line-height: 50px;
      text-align:center;
      color: $dark-brown-text-color;
    }
  }
}

.langs{
  text-align: center;

  li{
    position: relative;
    display: inline-block;

    &:nth-of-type(n+2){
      margin-left: 5px;
    }

    &:nth-of-type(n+2)::before{
      content: '/';
      padding-right: 10px;
    }

    a{
      color: $dirty-text-color;
      line-height: 50px;
      font-size: 16px;
    }
    &:hover{
      a{
        color: $dark-brown-text-color;
      }
    }
    &.active{
      a{
        color: $dark-brown-text-color;
      }
    }
  }
}
.dropdown-menu{
  background-color: transparent;
  box-shadow: none;
  border: none;
  text-align: center;
  position: relative;
  float: none;

  @media (min-width: $screen-sm-min) {
    // text-align: left;
  }

  >li{
    &:hover{
      >a{
        text-decoration: underline;
        color: $dark-brown-text-color;
        background-color: transparent;
      }
    }
    >a{
      color: $dirty-text-color;
      font-size: 22px;
      line-height: 35px;
      padding: 0;
    }
  }

  &--user{
    background-color: $dirty-bg-color;
    text-align: center;
    left: initial;
    right: 0;
    padding: 15px;
    box-sizing: content-box;
    border-radius: 0;
    position: absolute !important;

    @media (min-width: $screen-sm-min) {
      text-align: right;
    }

    >li{
      >a{
        color: $brown-text-color;
        font-size: 16px;
        line-height: 45px;

        @media (min-width: $screen-sm-min) {
          line-height: 35px;
        }
      }
    }
  }
}

.app-logo{
  width: 100px;
  height: 60px;
  position: relative;
  // display: inline-block;
  transition: .4s cubic-bezier(.215,.610,.355,1.000);
  margin-top: 10px;

  @media (min-width: $screen-sm-min) {
    margin-top: 0;
    margin-top: 20px;
    width: 120px;
    height: 80px;
  }
  a{
    margin: auto;
    position: absolute;
    bottom: 0;
    left:0;
    right:0;
    width: 100%;
    height: inherit;
    line-height:60px;
    text-indent: 2000em;
    // background: url('front/img/logo.svg') no-repeat center /contain;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;

    @media (min-width: $screen-sm-min) {
      line-height: 80px;
    }
  }

}
