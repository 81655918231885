
.call-to-action{
  height: auto;
  position: relative;
  padding: 25px 0;
  margin: auto;
  text-align: center;

  @media (min-width: $screen-md-min) {
    width: 60%;
  }

  &__header{
    position: relative;
    margin-bottom: 25px;
  }

  &__btn{
    margin-top: 30px;
  }

}
