.process-article{
  padding: 0 40px;

  &__text{
    color: $brown-text-color;
    transition: color 1s;
  }
  &__title{
    color: $brown-text-color;
    margin-bottom: 30px;
    font-size: 40px;
    line-height: 40px;
    transition: color 1s;
  }
  &.active{
    hr,
    .timeline__vetor::before{
      background-color: $dirty-text-color;
    }
    .process-order__number,
    .timeline__vetor::after{
      border-color: $dirty-text-color;
    }
    .process-order__caption .subtitle,
    .process-order__caption .text,
    .process-order__number .big-title,
    .process-article__title,
    .process-article__text{
      color: $dirty-text-color;
    }
  }
}


.info-card{

  &__img{
    width: 100%;
    height: 250px;
    background-color: white;

    img{
      margin: auto;
      width: 100%;
      height: auto;
      @extend .full-img;
    }
  }
  &__caption{
    overflow: hidden;
    max-height: 250px;
  }
}

.home-intro{

  &--margin{
    margin-bottom: 80px;

    @media (min-width: $screen-sm-min) {
      padding: 0 10%;
    }
  }

  &__logo{
    margin: auto;
    display: block;
    width: 40%;

    @media (min-width: $screen-sm-min) {
      width: initial;
    }
  }
  &__article{
    margin-top: 30px;
  }
  &__title{
    text-transform: uppercase;
    text-align: center;
  }
  &__divider{
    margin: 0 auto 25px;
    display: block;
    fill: $black-bg-color;

    @media (min-width: $screen-sm-min) {
      width: 60%;
    }
  }
}
