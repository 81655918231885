.slideshow-wrapper{
  position: relative;

  &--shrink{
    @media (min-width: $screen-sm-min) {
      padding: 0 5%;
    }
  }

}
.slideshow{
  position: relative;
  height: 450px;

  &__slider {
    position: relative;
    height: 100%;
    max-width: 100%;
    margin: 0 10px;

    @media (min-width: $screen-sm-min) {
      margin: 0 25px;
    }
  }

  &__content{
    position: relative;
    width: 100%;
    height: 450px !important;
  }

  /* image */
  &__image{
    // width: 100%;
    height: 100%;
    position:absolute;
    bottom:0;
    top:0;
    right:0;
    @extend .full-img;
    background-color: $white-bg-color;
    transition: top .8s;
    backface-visibility: hidden;
    width: 100%;
  }
  // CAPTION BOX
  &__caption {
    position: relative;
    height: auto;
    width: 240px!important;

    @media (min-width: $screen-sm-min) {
      padding-top:0;
      width: 400px!important;
    }
  }

  &__arrows{
    position: absolute;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
    z-index: 1;
    height:80px;
    margin: auto;

    @media (min-width: $screen-sm-min) {
      left: 25px;
      right: 25px;
    }

    *{
      display: inline-block;
      padding: 0;
    }

    .btn-link{
      text-align: center;
      margin: auto;
    }
    .icon{
      font-size: 25px;
      // width: 50px;
      color: $dirty-text-color;
      @extend .full-img;
      background-size: contain;
      height: 80px;
      width: 100px;

      &::before{
        display: block;
        width: 70px;
      }
      &:hover{
        color: $brown-text-color;
      }

      &--arrow-back{
        // background-image: url(/front/img/vetors/left-arrow-bg.png);
        float:left;
        text-align: right;
      }
      &--arrow-front{
        // background-image: url(/front/img/vetors/right-arrow-bg.png);
        float:right;
        text-align: center;
      }
    }
  }
}
