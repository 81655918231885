.filter-box{
  background-color: rgba(255, 255, 255, 0.1);
  padding: 40px 20px;
  margin-bottom: 20px;
  border: 2px solid $white-border-color;

  &__selector{

  }
  label{
    text-transform: uppercase;
  }
  label,
  select{
    color: $brown-text-color;
    display: block;
  }
  select{
    border: 2px solid $brown-border-color;
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    background-color: transparent;
    cursor:pointer;

    &:focus{
      background-color: $dirty-bg-color;
    }
  }
}
