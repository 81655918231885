.form{
  position: relative;
  padding-left: 0;
  padding-right: 0;

  &--newsletter{
    text-align: center;

    @media (min-width: $screen-sm-min) {
      width: 60% !important;
      margin: auto;
    }
    input[type="email"]::-webkit-input-placeholder {
      color: $dark-brown-text-color !important;
    }
    input{
      display: inline-block;
      vertical-align: top;
      width: 90% !important;
      background-color: $dirty-bg-color !important;
      border: none !important;

      &:hover,
      &:focus{
        color: $dirty-text-color;
        background-color: $light-brown-bg-color !important;
      }
      @media (min-width: $screen-md-min) {
        width: 75% !important;
      }
      @media (min-width: $screen-sm-min) {
        width: 60% !important;
      }
    }
    .btn{
      vertical-align: top;
      display: inline-block;
      background-color: $dark-brown-bg-color;
      border: none;
      color: $dirty-text-color;
      &:hover,
      &:focus{
        color: $dirty-text-color;
        background-color: $light-brown-bg-color !important;
      }
    }
  }
  select{
    cursor:pointer;
    background-color: transparent;
  }
  select,
  input{
    height: 50px;
    padding:0 10px;

    @media (min-width: $screen-sm-min) {
      padding:0 15px;
    }
  }
  textarea{
    padding: 15px;
  }

  input,
  select,
  textarea{
    border-radius: 0;
    background-color: $dirty-bg-color;
    border: 1px solid $dirty-border-color;
    color: $brown-text-color;
    font-size: 16px;
    width: 100%;
    margin-bottom: 10px;
    transition: .3s all;
    box-shadow: none;

    &::-webkit-input-placeholder {
      color: $brown-text-color;
    }

    &:focus,
    &:hover{
      border: 1px solid $brown-border-color;
      outline: none;
      box-shadow: none;
    }
  }

  &__footer{
    margin-top: 40px;
    text-align: center;

    .tip{
      display: block;
      color: $brown-text-color;
      text-align: center;
      margin-top: 10px;
    }
  }
  &--login{
    margin-bottom: 15px;
  }
}

.form-group{
  &--margin{
    margin-bottom: 30px;
  }
}
