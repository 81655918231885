body,
.editable{
  font-family: $font-family-primary;
  font-weight: $font-weight-regular;
  color: $black-text-color;
  letter-spacing: .08em;
  font-size: 16px;

  > ul{
    list-style-type: disc;
    list-style-position: inside;
    li{
      margin-top: 5px;
      > a{
        text-decoration: underline;
        color: $light-brown-text-color;
      }
    }
  }
  > h4{
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
  }
  img{
     max-width: 100%;
 }
}

.big-title{
  font-size: 40px;
  line-height: 45px;
  text-transform: uppercase;
  font-weight: $font-weight-light;

  @media (min-width: $screen-sm-min) {
    font-size: 70px;
    line-height: 85px;
  }
}

.title{
  font-size: 30px;
  line-height: 35px;
  font-weight: $font-weight-bold;
  text-transform: uppercase;


  @media (min-width: $screen-sm-min) {
    font-size: 36px;
    line-height: 45px;
  }
  &--regular{
    font-weight: $font-weight-regular;
  }
}

.subtitle{
  font-size: 20px;
  line-height: 24px;
  font-weight: $font-weight-bold;
  text-transform: uppercase;

  @media (min-width: $screen-sm-min) {
    font-size: 24px;
    line-height: 32px;
  }
  &--regular{
    text-transform: initial;
    font-weight: $font-weight-regular;
  }
}

.text{
  font-size: 18px;
  line-height: 28px;

  &--light{
    font-weight: $font-weight-light;
  }

  &--bold{
    text-transform: uppercase;
    font-weight: $font-weight-bold;
  }
  span{
    font-weight: $font-weight-bold;
  }
}

.small-text{
  font-size: 16px;
  line-height: 22px;

  &--light{
    font-weight: $font-weight-light;
  }
  &--bold{
    font-weight: $font-weight-bold;
  }
}

a{
  outline: none;
  transition: .3s all;

  &:active,
  &:focus,
  &:hover{
    outline: none;
    text-decoration: none;
    color: $light-brown-text-color;
  }
}
