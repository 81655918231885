/**
 * Font mixins
 *
 * Author: José Osório
 * Copyright 2016
 */


// ver https://infinitered.github.io/ionicons-version-3-search/

$icons: (
   /* miscelaneous */
   chat            : '\f11f',
   plus            : '\f217',
   plus-circle     : '\f48b',
   pencil          : '\f37e',
   users            : '\f47c',
   send            : '\f2f6',
   close           : '\f12a',
   clock           : '\f402',
   check           : '\f375',
   menu            : '\f130',
   link            : '\f1fe',
   download            : '\f420',
   share            : '\f3ac',
   points            : '\f450',
   search            : '\f4a4',
   user            : '\f3a0',
   play            : '\f488',
   pause            : '\f478',

   /* arrows */
   arrow-back       : '\f2ca',
   arrow-front      : '\f30f',
   arrow-down       : '\f3d4',
   arrow-up         : '\f3d7',

   arrow-right      : '\f3d6',
   arrow-left       : '\f3d5',

   caret-up        : '\f365',
   caret-down      : '\f35f',

   box-up          : '\f4cb',
   box-down        : '\f420',

   /* social media */
   facebook        : '\f231',
   youtube         : '\f24d',
   linkedin        : '\f239',
   twitter         : '\f243',
   google          : '\f234',
   instagram       : '\f350',
   pinterest       : '\f2b1',
);


.icon {
  transition: .3s all;

  &--left{
    margin-right: 5px;
  }
  &--right{
    margin-left: 5px;
  }
	&::before {
		font-family: $font-family-icons;
		font-style: normal;
	}

	@each $modifier, $code in $icons {
		&--#{$modifier}::before {
			content: $code;
		}
	}
}
