.banner{
  height: 220px;

  @media (min-width: $screen-sm-min) {
     height: 350px;
   }

  @media (min-width: $screen-md-min) {
     height: 500px;
   }

  &__img{
    width: 100%;
    height: 100%;
    @extend .full-img;

  }
}
.top-banner{
   position: relative;
  height: 300px;

  @media (min-width: $screen-sm-min) {
     height: calc(100vh - 400px);
  }

  @media (min-width: $screen-md-min) {
     height: 100vh;
  }
  .btn-video-close{
    display: none !important;
   }
  .btn-video-open,
  .btn-video-close{

    &.appear{
      display: block !important;
    }
    &.disappear{
      display: none !important;
    }
  }

  .btn-video{
     border: 2px solid white;
     border-radius: 25px;
     position: absolute;
    bottom: 10px !important;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    color: #fff;
    opacity: .5;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
      width: 50px;
      height: 50px;
      line-height: 46px;

      @media (min-width: $screen-sm-min) {
         bottom: 80px !important;
      }
    .icon--play{
      padding-left: 5px;
    }
    .icon::before{
      font-size: 32px;
      @media (min-width: $screen-sm-min) {
         font-size: 42px;
       }
    }

  }
  &__video{
     position: relative;
      width: 100%;
      height: 100%;

      #myVideo {
         position: absolute;
         top: 0;
         right: 0;
         bottom: 0;
         max-width: 100%;
         min-height: 100%;
         z-index: 1;
         top: 50%;
         left: 50%;
         -o-object-fit: cover;
         object-fit: cover;
         transform: translateX(-50%) translateY(-50%);
         -webkit-transform: translateX(-50%) translateY(-50%);
      }
  }
  &__img{
    opacity: .4;
    width: 100%;
    height: 100vh;
    @extend .full-img;
    position: absolute;
  }
  &__scroll{
    cursor:pointer;
    z-index: 1;
    display: block;
    margin: 15px auto;
    color: $light-brown-text-color;
    width:60px;
    height:60px;
    text-align: center;
    font-size: 60px;
    position: absolute;
    bottom: 80px;
    left: 0;
    right: 0;

    &:hover{
      transform: translateY(-25px);
    }
  }
  &__caption{
   @extend .abs-center;
   z-index: 1;
   width: 100%;
   height: 300px;

   @media (min-width: $screen-sm-min) {
      height: calc(100vh - 400px);
   }

   @media (min-width: $screen-md-min) {
      height: 100vh;
   }
  }


  &__logo{
    opacity: .8;
    width: 75%;
    height: 400px;
    -webkit-order: 0;
   -ms-flex-order: 0;
   order: 0;
   -webkit-flex: 0 1 auto;
   -ms-flex: 0 1 auto;
   flex: 0 1 auto;
   -webkit-align-self: center;
   -ms-flex-item-align: center;
   align-self: center;

   @media (min-width: $screen-sm-min) {
     width: 625px;
   }
  }
}
