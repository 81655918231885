.agents-list{
  margin-top: 40px;

  @media (min-width: $screen-sm-min) {
    margin-top: 100px;
  }
  &__element{

    &:nth-of-type(n+2){
      margin-top: 25px;
    }

    @media (min-width: $screen-sm-min) {

      &:nth-of-type(n+2){
        margin-top: 0;
      }
      &:nth-of-type(n+4){
        margin-top: 30px;
      }
    }
  }
}
.contacts-list{
  margin-top: 40px;

  @media (min-width: $screen-sm-min) {
    margin-top: 100px;
  }

  &__element{

    &:nth-of-type(n+2){
      margin-top: 40px;
    }

    @media (min-width: $screen-sm-min) {

      &:nth-of-type(n+2){
        margin-top: 0;
      }
    }
  }
}
.process-order-list{
  margin-top: 40px;

  @media (min-width: $screen-sm-min) {
    margin-top: 60px;
  }

  &__element{

    &:nth-of-type(n+2){
      margin-top: 40px;
    }

    @media (min-width: $screen-sm-min) {

      &:nth-of-type(n+2){
        margin-top: 0;
      }
      &:nth-of-type(n+3){
        margin-top: 40px;
      }
    }
    @media (min-width: $screen-md-min) {

      &:nth-of-type(n+3){
        margin-top: 0;
      }
    }
  }
  &.active{
    .process-order__number{
      border-color: $dirty-text-color;
    }
    .process-order__caption .subtitle,
    .process-order__caption .text,
    .process-order__number .big-title,
    .process-article__title,
    .process-article__text{
      color: $dirty-text-color;
    }
  }
}

.products-header{
  margin: 40px 0;
}

.products-list{

  &__element{
    &:nth-of-type(n+2){
      margin-top: 40px;
    }
    @media (min-width: $screen-sm-min) {
      &:nth-of-type(n+2){
        margin-top: 0;
      }
      &:nth-of-type(n+5){
        margin-top: 40px;
      }
    }
  }
}

.teaser-list{

   @media (min-width: 769px) {
    // margin-bottom: -120px;
    margin-bottom: -60px;
  }

  &--filter{

    .teaser-list__element:hover{

      .teaser-card__img::before{
        opacity: 0;
      }
    }
    .teaser-card__img::before{
      content: '';
      opacity: .4;
      background-color: $brown-bg-color;
      @extend .abs-center;
      transition: opacity .3s;
    }
  }

  &__item{
    position: relative;

    &:nth-of-type(4n-2){
      margin-top: 40px;

      @media (min-width: $screen-sm-min) {
        margin-top: 0;
        transform: translateY(-100px);
      }
      @media (min-width: $screen-md-min) {
        transform: translateY(-80px);
        // top:-80px;
      }
    }
    &:nth-of-type(4n-1){
      margin-top: 40px;

      @media (min-width: $screen-sm-min) {
        margin-top: 0;
        transform: translateY(-130px);
      }
      @media (min-width: $screen-md-min) {
        transform: translateY(-160px);
      }
    }
    &:nth-of-type(4n){
      margin-top: 40px;

      @media (min-width: $screen-sm-min) {
        margin-top: 0;
        transform: translateY(-160px);
      }
      @media (min-width: $screen-md-min) {
        margin-top: 0;
        transform: translateY(-240px);
      }
    }
    &:nth-of-type(odd){
      text-align: right;
      .btn-link{
        text-align: right;
      }
      .teaser-card__title {
        text-align: right;
      }
      .teaser-card__img{
        float: right;

        @media (min-width: $screen-sm-min) {
          // transform: translateX(-65px);
        }
      }
      .teaser-list__element{
        float: right;
      }
    }

    &:nth-of-type(even){
      text-align: left;

      .teaser-card__img{
        float: left;

        @media (min-width: $screen-sm-min) {
          // transform: translateX(65px);
        }
      }
      .teaser-card__image{
        float: right;
      }
    }
  }
}

.cards-list{
  padding-top: 30px;

  @media (min-width: $screen-sm-min) {
    padding-top: 60px;
  }
  &--sub{
    .img-card:hover{
      .btn-link,
      .img-card__text,
      .img-card__title{
        color: $light-brown-text-color;
      }
    }
  }
  &__element{
    opacity: .6;
    transition: .7s all ease-out;
    transition-delay: .7s;
    cursor: pointer;

    .img-card{
      text-align: center;

      @media (min-width: $screen-sm-min) {
        text-align: left;
      }
    }
    &:nth-of-type(3n-1){
      .img-card{
        text-align: center;
      }
    }

    &:nth-of-type(3n){
      .img-card{
        text-align: center;

        @media (min-width: $screen-sm-min) {
          text-align: right;
        }
      }
    }

    &:nth-of-type(n+2){
      margin-top: 40px;
    }
    @media (min-width: $screen-sm-min) {
      &:nth-of-type(n+2){
        margin-top: 0;
      }
      &:nth-of-type(3n-2){
        margin-top: 100px;
      }
      &:nth-of-type(3n){
        margin-top: 100px;
      }
    }

    &.show{
      opacity: 1;
      @media (min-width: $screen-sm-min) {
        transform: translateY(-50px);
      }
    }
  }
}

.topics-list{

  &__element{

    &:nth-of-type(n+2){
      margin-top: 30px;

      @media (min-width: $screen-sm-min) {
        margin-top: 0;
      }
    }
  }
}
