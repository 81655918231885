.timeline{
  position: relative;
  display: block;
  max-width: 100%;
  overflow: hidden;

  hr{
    width: 2px;
    height: 100px;
    background-color: $light-brown-bg-color;
    margin: auto;
    border: none;
    position: relative;
    margin-bottom: 20px;
  }
  &__element{
    padding-top: 50px;
    margin-top: 50px;
    margin-bottom: 40px;
    position: relative;
    display: block;
    clear: both;
    position: relative;
    transition: color .3s, transform .8s, opacity 1.3s;
    text-align: center;
    // opacity:0;

    @media (min-width: $screen-sm-min) {
      margin: 80px auto;
    }

  }

  &__vetor{
    transition: 1.2s color;

    //circle
    &::before{
      z-index: 2;
      position: absolute;
      display: block;
      width: 20px;
      height: 20px;
      top: -3px;
      margin: auto;
      border-radius: 50px;
      background-color: $light-brown-border-color;
      content: '';
      left: 0;
      right: 0;
    }
    //border
    &::after{
      position: absolute;
      display: block;
      width: 45px;
      height: 45px;
      top: -15px;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 50px;
      content: '';
      background-color: $black-border-color;
      border: 2px solid $light-brown-border-color;
    }
    &.active{

      &::before{
        background-color: $dirty-text-color;
      }
      &::after{
        border-color: $dirty-text-color;
      }
    }
  }

}
