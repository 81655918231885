.agent-card{
  text-align: center;
  color: $light-brown-text-color;

  .subtitle{
    overflow: hidden;
    min-height: 60px;
  }
  &__name{
    margin: 10px 0;
    text-transform: uppercase;
  }
  &__info{
    color: $dirty-text-color;
    margin-top: 10px;
    min-height: 140px;
    overflow: hidden;

    .text{
      font-size: 16px;
    }
  }
}

.contact-card{

  &__caption{
    padding: 25px 0;
    text-align: center;
  }
  &__info{
    margin-top: 25px;
    min-height: 72px;
  }
  .divider{
      fill: $brown-bg-color;

    &:first-of-type{
      transform: rotateX(180deg);
    }
    &:last-of-type{
      display: none;

      @media (min-width: $screen-sm-min) {
        display: block;
      }
    }
  }
}
.process-order{
  text-align: center;

  &__number{
    margin: auto;
    width: 200px;
    height: 200px;
    text-align: center;
    border-radius: 100px;
    border: 5px solid $light-brown-border-color;

    .big-title{
      transition: 1s color;
      line-height: 200px;
      color: $brown-text-color;
    }
  }
  &__caption{
    margin-top: 25px;
    color: $black-text-color;

    .text{
      transition: 1s color;
      margin-top: 25px;
      color: $black-text-color;
    }
  }
}

.detail-info{

  &--news{
    // margin-top: 60px;
  }
  &__title{
    margin-bottom: 20px;
  }
  &__text{

  }
  &__detail{
    margin-top: 30px;
    a{
      text-decoration: underline;
    }
    > p {
      margin-bottom: 10px;
    }

    @media (min-width: $screen-sm-min) {
      margin-top: 0;
    }
  }
  &__footer{
    margin-top: 60px;

   .social{
      float: left;
      text-align: left;
      margin-top: 0 !important;
   }

   .back{
      float: right;
      text-align: right;
   }
   .btns{
      cursor: pointer;
       display: inline-block;
       width: 50px;
       line-height: 50px;
       font-size: 22px;
       border: 1px solid #fff;
       text-align: center;
       transition: .3s all;

       &:hover{
          color: #20190f;
          opacity: .7;
       }
   }
  }
}
.product-card{
  cursor: pointer;
  transition: .3s all;

  &:focus,
  &:hover{
    color:$light-brown-text-color;
  }

  &__figure{
    position: relative;
  }
  &__label{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 80px;
    height: 70px;
  }
  &__header{
    margin-top: 10px;
    margin-bottom: 20px;

    @media (min-width: $screen-sm-min) {
      overflow: hidden;
      min-height: 150px;
      max-height: 150px;
    }
    @media (min-width: $screen-md-min) {
      min-height: 90px;
      max-height: 90px;
    }
  }
  &__category{
    text-transform: uppercase;
  }
  &__img{
    width: 100%;
    height: 350px;
    @extend .full-img;
  }
  &__description{

    @media (min-width: $screen-sm-min) {
      min-height: 110px;
      max-height: 110px;
      overflow: hidden;
    }
    @media (min-width: $screen-md-min) {
      min-height: 90px;
      max-height: 90px;
    }
    span{
      font-weight: $font-weight-bold;
    }
  }
}
.img-card{
  text-align: center;
  color: $brown-text-color;

  &:focus,
  &:hover{
    .btn-link,
    .img-card__text,
    .img-card__title{
      color:$dirty-text-color;
    }
  }
  &__figure{
    overflow: hidden;
    width: 100%;
    height: 400px;

    @media (min-width: $screen-sm-min) {
      height: 310px;
    }
    @media (min-width: $screen-md-min) {
      height: 400px;
    }
    @media (min-width: $screen-lg-min) {
      height: 450px;
    }
  }
  &__img{
    width: 100%;
    height: 100%;
    @extend .full-img ;
    transition: all .3s ease-in;
  }
  &__title{
    margin-top: 20px;
    overflow: hidden;
    max-height: 70px;
    min-height: 70px;
    color:$brown-text-color;
    transition: .3s all;

    @media (min-width: $screen-sm-min) {
      font-size: 21px;
      max-height: 60px;
      min-height: 60px;
    }
  }
  &__text{
    color:$brown-text-color;
    margin-top: 30px;
    overflow: hidden;
    max-height: 95px;
    min-height: 95px;
    transition: .3s all;
  }
  .btn-link{
    margin-top: 30px;
    color:$brown-text-color;
  }
}

.topic-card{
  text-align: center;
  // color: $dirty-text-color;

  &__title{
    margin-bottom: 25px;
  }
  &__text{
    // color: $dirty-text-color;
    // overflow: hidden;
    // max-height: 120px;
  }
  .btn-link{
    margin: 30px auto 0;
  }
}

.teaser-card{
  cursor: pointer;

  &:hover{
    .btn-link,
    .teaser-card__title,
    .teaser-card__text{
      color: $dirty-text-color;
    }
  }

  &--sub{
    cursor: initial;
    .teaser-card__text{
      max-height: initial;
    }
    &:hover{
      .btn-link,
      .teaser-card__title,
      .teaser-card__text{
        color: $light-brown-text-color;
      }
    }
  }
  &__title{
    color: $light-brown-text-color;
    transition: .3s all;
    font-size: 20px;
    line-height: 25px;

    @media (min-width: $screen-sm-min) {
      font-size: 30px;
      line-height: 35px;
    }
    @media (min-width: $screen-md-min) {
      font-size: 35px;
      line-height: 45px;
      margin-bottom: 20px;
    }
  }
  &__text{
    color: $light-brown-text-color;
    margin-bottom: 20px;
    overflow: hidden;
    max-height: auto;
    transition: .3s all;

    @media (min-width: $screen-sm-min) {
      max-height: 240px;
    }
    @media (min-width: $screen-md-min) {
      max-height: 170px;
    }
  }
  .btn-link{
    color: $light-brown-text-color;
  }
  &__image{}
  &__img{
    position: relative;
    box-shadow: 5px 5px 30px #000000;
    height: 210px;
    width: 100%;
    @extend .full-img;

    @media (min-width: $screen-sm-min) {
      width: 250px;
      height: 250px;
    }

    @media (min-width: $screen-md-min) {
      width: 350px;
      height: 350px;
    }
  }
}
